import { useLayoutEffect, useRef, useState } from "react";

import Cleave from "cleave.js/react";
import moment from "moment";

function AddTimer({ isOpen, onCancel, onAdd }) {
  const initialTimerField = "00:00:00";

  const [newTimeField, setNewTimeField] = useState(initialTimerField);
  const inputRef = useRef()

  const handleAdd = () => {
    onAdd(moment.duration(newTimeField).asSeconds());
    setNewTimeField(initialTimerField);
    onCancel();
  };

  useLayoutEffect(() => {
      if(isOpen) {
        inputRef.current.element.focus()
      }
  },[isOpen])

  return (
    <div
      className={[
        "fixed inset-0 w-screen h-screen flex z-50",
        isOpen ? "block" : "hidden",
      ].join(" ")}
    >
      <div
        className="absolute inset-0 bg-opacity-80 bg-red-600"
        onClick={onCancel}
      ></div>
      <div className="relative w-full max-w-screen-sm place-self-center mx-auto p-6">
        <div className="bg-white p-6 rounded-xl">
          <Cleave
            className="timer-item-text inline-block"
            size="8"
            ref={inputRef}
            value={newTimeField}
            options={{
              time: true,
              timePattern: ["h", "m", "s"],
            }}
            onChange={(e) => {
              setNewTimeField(e.target.value);
            }}
          />

          <div className="flex justify-end mt-6 space-x-2">
              <button className="btn" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                disabled={moment.duration(newTimeField).asSeconds() === 0}
                onClick={handleAdd}
              >
                Add timer
              </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTimer;
