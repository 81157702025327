import {
  AddToPhotos,
  Delete,
  Done,
  Edit,
  NotificationsActive,
} from "@material-ui/icons";
import { useEffect, useState } from "react";

import Cleave from "cleave.js/react";
import ProgressArc from "./ProgressArc";
import alarmSound from "./chime.wav";
import { formatTime } from "./functions";
import moment from "moment";
import useSound from "use-sound";

function TimerItem({
  timerObject,
  index,
  isCounting,
  updateCallback,
  duplicateCallback,
  removeCallback,
}) {
  const { timer, timeRemaining } = timerObject;
  const [alarm, setAlarm] = useState(false);
  const [alarmHasPlayed, setAlarmHasPlayed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(formatTime(timer));
  const elapsed = timer - timeRemaining;

  const [playAlarm] = useSound(alarmSound);

  const update = () => {
    updateCallback(index, moment.duration(editingValue).asSeconds());
    setIsEditing(false);
  };

  useEffect(() => {
    // When the timer is reset
    if (elapsed === 0) {
      setAlarmHasPlayed(false);
      setAlarm(false)
    }

    // When we run out of time
    if (timeRemaining === 0 && !alarmHasPlayed) {
      setAlarmHasPlayed(true);
      setAlarm(true);
      playAlarm();
    }
  }, [elapsed, timeRemaining, alarmHasPlayed, playAlarm]);

  return (
    <div
      key={index}
      className={["timer-item", isEditing ? "timer-item--is-editing" : ""].join(
        " "
      )}
    >
      <div className="w-12 flex-none">
        {alarm && (
          <NotificationsActive
            onClick={() => setAlarm(false)}
            fontSize="large"
            className="align-middle"
          />
        )}{" "}
        {!alarm && (
          <ProgressArc
            value={elapsed}
            max={timer}
            className="text-blue-600 w-8 inline-block align-middle"
          />
        )}
      </div>

      <div className="flex-grow">
        {isEditing && (
          <Cleave
            className="timer-item-text inline-block text-gray-600"
            size="8"
            value={formatTime(timer)}
            options={{
              time: true,
              timePattern: ["h", "m", "s"],
            }}
            onChange={(e) => {
              setEditingValue(e.target.value);
            }}
          />
        )}
        {!isEditing && (
          <span className="timer-item-text inline-block py-1">
            {formatTime(timeRemaining)}
          </span>
        )}
      </div>

      <div className="flex-none">
        {!isCounting && (
          <>
            {isEditing ? (
              <button
                className="btn btn-icon"
                onClick={() => {
                  update();
                }}
              >
                <Done />
              </button>
            ) : (
              <button
                className="btn btn-icon"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <Edit />
              </button>
            )}

            <button
              onClick={() => duplicateCallback(index)}
              className="btn btn-icon"
            >
              <AddToPhotos />
            </button>
            <button
              onClick={() => removeCallback(index)}
              className="btn btn-icon"
            >
              <Delete />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default TimerItem;
