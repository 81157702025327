import moment from "moment";

function formatTime (duration) {
    const momentDuration = moment.duration(duration * 1000);

    return `${momentDuration.get("hours").toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${momentDuration.get("minutes").toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${momentDuration.get("seconds").toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;
  };
  

  export {formatTime}