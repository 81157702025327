import arc from 'svg-arc';

function ProgressArc({value, max, className, outline, ...rest}) {
    const d = arc({
        x: 150,
        y: 150,
        r: 149,
        R: outline ? 130 : 0,
        start: value / max * 360,
        end: 360,
      });
    
    return (
        <svg width="60" viewBox="0 0 300 300" {...rest} className={[className, 'fill-current'].join(" ")}>
            <path d={d} fillRule="evenodd"/>
        </svg>
    )
}

export default ProgressArc