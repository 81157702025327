import { Pause, PlayArrow, Replay } from "@material-ui/icons";

import ProgressArc from "./ProgressArc";
import { formatTime } from "./functions";

function MasterControls({
  playPauseCallback,
  resetCallback,
  duration,
  elapsed,
  isEditing,
  isCounting,
}) {
  const totalTimeRemaining = duration - elapsed;

  return (
    <div className="flex border-t border-gray-300 p-6 items-center">
      <div className="w-24 relative mr-2">
        <ProgressArc
          value={elapsed}
          max={duration}
          outline
          className="text-red-600 w-full mx-auto"
        />
        <div className="absolute w-full h-full absolute top-0 left-0 flex justify-center items-center leading-none">
          <button
            onClick={() => playPauseCallback(!isCounting)}
            className="text-5xl"
          >
            {isCounting ? (
              <Pause style={{ fontSize: "inherit" }} />
            ) : (
              <PlayArrow style={{ fontSize: "inherit" }} />
            )}
          </button>
        </div>
      </div>
      <div>

      <p className="text-center text-5xl px-4">
        {formatTime(totalTimeRemaining)}
      </p>
      <p className="">
        <button onClick={resetCallback} className="btn">
          <Replay /> Reset timer
        </button>
      </p>
      </div>
    </div>
  );
}

export default MasterControls;
