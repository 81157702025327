import "./App.css";

import AddTimer from "./AddTimer";
import MasterControls from "./MasterControls";
import TimerItem from "./TimerItem";
import useInterval from "./useInterval";
import { useState } from "react";

function App() {
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [addTimerModal, setAddTimerModal] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const [timers, setTimers] = useState([
    {
      timer: 5,
    },
  ]);
  const timersArray = getTimers(timers, elapsedSeconds);
  const totalTimer = timers.reduce((prev, curr) => prev + curr.timer, 0);

  function getTimers(timersArray, elapsed) {
    let accumulator = 0;
    return timersArray.map((timer) => {
      const prevAccumulator = accumulator;
      accumulator = accumulator + timer.timer;
      let timeRemaining = timer.timer;

      if (elapsed > prevAccumulator) {
        timeRemaining = timer.timer - elapsed + prevAccumulator;
      }

      if (elapsed >= accumulator) {
        timeRemaining = 0;
      }

      return {
        timer: timer.timer,
        timeRemaining: timeRemaining,
      };
    });
  }

  const addTimer = (time) => {
    const timersCopy = JSON.parse(JSON.stringify(timers));

    timersCopy.push({
      timer: time,
      timeRemaining: time,
    });

    setTimers(timersCopy);
  };

  const duplicateTimer = (index) => {
    const timersCopy = JSON.parse(JSON.stringify(timers));

    timersCopy.splice(index + 1, 0, {
      ...timersCopy[index],
    });

    setTimers(timersCopy);
  };

  const removeTimer = (index) => {
    const timersCopy = JSON.parse(JSON.stringify(timers));

    timersCopy.splice(index, 1);

    setTimers(timersCopy);
  };

  const updateTimer = (index, time) => {
    const timersCopy = JSON.parse(JSON.stringify(timers));

    timersCopy[index] = {
      timer: time,
    };

    setTimers(timersCopy);
  };

  const resetAllTimers = () => {
    setElapsedSeconds(0);
  };

  function handlePlayPause() {
    setIsCounting(!isCounting);

    if (elapsedSeconds === totalTimer) {
      resetAllTimers();
    }
  }

  const clearAlarm = (index) => {
    const timersCopy = JSON.parse(JSON.stringify(timers));

    timersCopy[index].alarm = false;

    setTimers(timersCopy);
  };

  useInterval(
    () => {
      console.log("Tick");

      if (elapsedSeconds < totalTimer) {
        setElapsedSeconds(elapsedSeconds + 1);
      }

      if (elapsedSeconds === totalTimer - 1) {
        setIsCounting(false);
      }
    },
    isCounting ? 1000 : null
  );

  return (
    <div className="App mx-auto full-height flex bg-gray-900">
      <div className="flex self-center flex-grow hidden lg:block">
        <h1 className="text-center text-5xl text-white">TimeCop</h1>
      </div>
      <div className="flex flex-col flex-grow bg-white w-full h-full lg:max-w-screen-sm">
        <div className="flex-grow relative flex overflow-hidden">
          <div className="pb-2 h-full flex-grow overflow-y-auto">
            {timersArray.map((timer, index) => (
              <TimerItem
                key={index}
                index={index}
                timerObject={timer}
                isCounting={isCounting}
                updateCallback={updateTimer}
                duplicateCallback={duplicateTimer}
                removeCallback={removeTimer}
                clearAlarmCallback={clearAlarm}
              />
            ))}

            {!isCounting && (
              <AddTimer
                isOpen={addTimerModal}
                onCancel={() => setAddTimerModal(false)}
                onAdd={addTimer}
              />
            )}
          </div>
          {!isCounting && (
            <button
              className="absolute bottom-0 right-0 mb-6 mr-6 bg-red-600 rounded-full w-16 h-16 text-white text-5xl"
              onClick={() => setAddTimerModal(true)}
            >
              +
            </button>
          )}
        </div>
        <div className="flex-shrink-0">
          <MasterControls
            elapsed={elapsedSeconds}
            duration={totalTimer}
            isCounting={isCounting}
            playPauseCallback={handlePlayPause}
            resetCallback={resetAllTimers}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
